import React, { useState, useContext, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context";
import { axiosCall } from "../../Config";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function Words() {
	const context = useContext(Context);
	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const handleErrorAlert = (err) => {
		setErrorMessage(err.response.data.message);
		setTimeout(() => setErrorMessage(""), 3000);
	};

	useEffect(() => {
		if (context.state.selectedCategory == null) {
			context.selectCategory(context.state.categories[0]);
		}
	}, []);

	const questionReference = useRef(null);
	const answerReference = useRef(null);
	const alertReference = useRef(false);
	const [networkResultStatus, setNetworkResultStatus] = useState("");
	useEffect(() => questionReference?.current?.focus(), []);

	const AddWord = () => {
		setIsLoading(true);
		axiosCall(
			"post",
			"/question",
			{
				categoryId: context.state.selectedCategory.id,
				value: questionReference.current.value.trim().toLocaleLowerCase("tr"),
				answers: [answerReference.current.value.trim().toLocaleLowerCase("tr")],
			},
			context.state.token.value
		)
			.then((_) => {
				clearWord();
				setNetworkResultStatus("success");
			})
			.catch((err) => {
				setNetworkResultStatus("danger");
				handleErrorAlert(err);
			})
			.finally(() => setIsLoading(false));
	};

	const clearWord = () => {
		questionReference.current.value = "";
		answerReference.current.value = "";
	};

	useEffect(() => {
		if (networkResultStatus != "") {
			setTimeout(() => {
				alertReference.current.hidden = true;
				setNetworkResultStatus("");
			}, 3000);
			alertReference.current.hidden = false;
			questionReference.current.focus();
		}
	}, [networkResultStatus]);

	return (
		<div>
			<Alert
				ref={alertReference}
				hidden={true}
				className="maxW"
				style={{ marginBottom: "3rem" }}
				variant={networkResultStatus}
			>
				{networkResultStatus == "success" ? "Success! " : errorMessage}
			</Alert>
			<div>
				<Form.Label>Categories</Form.Label>
				<Form.Control
					style={{ backgroundColor: "#181A1B", color: "white" }}
					as="select"
					aria-label="Default select example"
					value={
						context.state.selectedCategory != null
							? context.state.categories.filter(
									(x) => x.id == context.state.selectedCategory.id
								).name
							: context.state.categories[0]?.name
					}
					onChange={(e) => {
						context.selectCategory(
							context.state.categories.filter((x) => x.id == e.target.value)[0]
						);
					}}
				>
					{context.state.categories.map((each) => (
						<option key={each.id} value={each.id}>
							{each.name}
						</option>
					))}
				</Form.Control>
			</div>
			<form
				className="maxW"
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<div className="input-group mt-5 mb-3 maxW">
					<span
						className="input-group-text bg"
						id="inputGroup-sizing-default"
						style={{ width: "100px", textAlign: "center" }}
					>
						Question
					</span>
					<input
						type="text"
						className="form-control bg"
						aria-label="Sizing example input"
						aria-describedby="inputGroup-sizing-default"
						ref={questionReference}
						name="question"
					/>
				</div>
				<div className="input-group mb-3 maxW">
					<span
						className="input-group-text bg"
						id="inputGroup-sizing-default"
						style={{ width: "100px", display: "inline-block" }}
					>
						Answer
					</span>
					<input
						type="text"
						className="form-control bg"
						aria-label="Sizing example input"
						aria-describedby="inputGroup-sizing-default"
						name="answer"
						ref={answerReference}
					/>
				</div>
				<button
					className="btn btn-outline-light"
					type="submit"
					onClick={AddWord}
				>
					add
				</button>
				<button
					type="button"
					className="btn btn-outline-danger mx-2"
					onClick={clearWord}
				>
					Clear
				</button>
			</form>
		</div>
	);
}
