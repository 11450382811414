import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { axiosCall } from "../../Config";
import classes from "./customModal.module.css";

function AddCategoryModal({ visible, setVisible }) {
	const ref = React.useRef("");
	const submit = () =>
		axiosCall("post", "/category", {
			name: ref.current.value?.trim()?.toLocaleLowerCase("tr"),
		});

	return (
		<Modal show={visible} onHide={() => setVisible(false)} centered>
			<Modal.Header className={classes.header}>
				<Modal.Title>Category</Modal.Title>
			</Modal.Header>
			<Modal.Body className={classes.body}>
				<h2>Add a category</h2>
				<div className={classes.input_group}>
					<input
						className="form-control bg"
						type="text"
						placeholder="category"
						ref={ref}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer className={classes.footer}>
				<Button variant="secondary" onClick={() => setVisible(false)}>
					Close
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						submit().finally(() => setVisible(false));
					}}
				>
					Save Changes
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default AddCategoryModal;
