import React, { useState, useContext } from "react";
import { Context } from "../../Context";
import { Link, Redirect } from "react-router-dom";
import { axiosCall } from "../../Config";

import logo from "../../images/logo.svg";

const Login = (props) => {
	const context = useContext(Context);
	const [auth, setAuth] = useState({ email: "", password: "" });
	const setAuthHandler = (e) => {
		setAuth({ ...auth, [e.target.id]: e.target.value });
	};

	const handleLogin = () =>
		axiosCall("post", "/account/login", { ...auth })
			.then((res) => {
				context.initializeState(res.data, true);
				localStorage.setItem("token-value", res.data.token);
				localStorage.setItem("token-expiration", res.data.expireInMinutes);
			})
			.catch((err) => console.log(err));

	return context.state.isLogin ? (
		<Redirect to={"/question"} />
	) : (
		<div className="LoginBody maxW">
			<div className="form-signin">
				<img className="mb-4" src={logo} alt="" width="150" height="120" />
				<h1 className="h3 mb-3 fw-normal">Please sign in</h1>
				<form className="w-300">
					<label htmlFor="email" className="visually-hidden">
						Email
					</label>
					<input
						type="text"
						id="email"
						className="form-control"
						value={auth.email}
						onChange={setAuthHandler}
						placeholder="Email"
						required
						autoFocus
					/>
					<label htmlFor="inputPassword" className="visually-hidden">
						Password
					</label>
					<input
						type="password"
						id="password"
						className="form-control mb-5"
						value={auth.password}
						onChange={setAuthHandler}
						placeholder="Password"
						required
					/>
					<button
						className="w-75 mb-2 btn btn-lg "
						onClick={(e) => {
							e.preventDefault();
							handleLogin();
						}}
						style={{ background: "#FFAB00" }}
						type="submit"
					>
						{" "}
						Sign in{" "}
					</button>
					<Link to={"/signup"}>
						<button
							className="btn btn-lg "
							style={{ background: "#FFAB00", width: "150px" }}
						>
							{" "}
							Sign up{" "}
						</button>
					</Link>
				</form>
				<p className="mt-5 mb-3 text-muted">&copy; 2020-2021</p>
			</div>
		</div>
	);
};

export default Login;
