import React, { useState } from "react";
import { axiosCall } from "../../Config";

import logo from "../../images/logo.svg";

const Signup = (props) => {
	const [auth, setAuth] = useState({
		name: "",
		email: "",
		password: "",
		phone: "",
	});
	const setAuthHandler = (e) => {
		setAuth({ ...auth, [e.target.id]: e.target.value });
	};

	const signupHandler = () =>
		axiosCall("post", "/account", auth)
			.then((_) => {
				window.location.replace("/login");
			})
			.catch((err) => console.log(err));

	return (
		<div className="LoginBody maxW">
			<div className="form-signin">
				<img className="mb-4" src={logo} alt="" width="150" height="120" />
				<h1 className="h3 mb-3 fw-normal">Sign up</h1>
				<form>
					<label htmlFor="name" className="visually-hidden">
						Name
					</label>
					<input
						type="text"
						id="name"
						className="form-control mb-2"
						value={auth.name}
						onChange={setAuthHandler}
						placeholder="Name"
						required
						autoFocus
					/>
					<label htmlFor="email" className="visually-hidden">
						Email
					</label>
					<input
						type="email"
						id="email"
						className="form-control mb-2"
						value={auth.email}
						onChange={setAuthHandler}
						placeholder="Email"
						required
					/>
					<label htmlFor="phone" className="visually-hidden">
						Phone
					</label>
					<input
						type="phone"
						id="phone"
						className="form-control mb-2"
						value={auth.phone}
						onChange={setAuthHandler}
						placeholder="05xx"
						required
					/>
					<label htmlFor="password" className="visually-hidden">
						Password
					</label>
					<input
						type="password"
						id="password"
						className="form-control mb-5"
						value={auth.password}
						onChange={setAuthHandler}
						placeholder="Password"
						required
					/>
					<button
						className="w-100 btn btn-lg "
						onClick={(e) => {
							e.preventDefault();
							signupHandler();
						}}
						style={{ background: "#FFAB00" }}
						type="submit"
					>
						{" "}
						Sign up{" "}
					</button>
				</form>
				<p className="mt-5 mb-3 text-muted">&copy; 2020-2021</p>
			</div>
		</div>
	);
};

export default Signup;
