import React, { useContext } from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import Questions from "./questions/Questions";
import Words from "./words/Words";
import Login from "./login/Login";
import Signup from "./signup/Signup";
import Home from "./Home";
import Logout from "./Logout";
import Categories from "./categories/Categories";
import Header from "../common/components/Header";
import List from "./categories/List";

import { Context } from "../Context";

export default function RouteComponent() {
	const context = useContext(Context);
	return (
		<Router>
			{context.state.isLogin ? <Header /> : null}
			<Switch>
				{context.state.isLogin ? (
					<React.Fragment>
						<Route path="/logout" component={Logout} />
						<Route path="/signup" component={Signup} />
						<Route path="/question" component={Questions} />
						<Route path="/category" component={Categories} />
						<Route path="/list" exact component={List} />
						<Route path="/words" component={Words} />
						<Route path="/" component={Home} />
					</React.Fragment>
				) : (
					<React.Fragment>
						<Route path="/login" component={Login} />
						<Route path="/signup" component={Signup} />
						<Redirect to="/login" />
					</React.Fragment>
				)}
			</Switch>
			{/* </div> */}
		</Router>
	);
}
