import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context";
import classes from "./categories.module.css";
import minus from "../../images/inverted-minus.png";
import menu from "../../images/hamburger-inverted.png";

export default function CategoryBody({
	isLoading,
	setDeleteCategoryState,
	setDeleteCategoryModalVisibility,
	setVisible,
}) {
	const context = useContext(Context);
	const history = useHistory();

	return (
		!isLoading && (
			<div className={classes.categories}>
				<div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						//width="200"
						//height="200"
						fill="currentColor"
						viewBox="0 0 16 16"
						onClick={() => {
							setVisible(true);
						}}
						className={classes.svg}
					>
						<path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
					</svg>
				</div>
				{context.state.categories.length > 0 &&
					context.state.categories.map((each) => (
						<div key={each.id} className={classes.box_container}>
							<img
								src={menu}
								className={classes.box_container_menu}
								onClick={() => {
									context.selectCategory(each);
									history.push("/list");
								}}
							/>
							<img
								src={minus}
								className={classes.box_container_remove}
								onClick={() => {
									setDeleteCategoryState(each.id);
									setDeleteCategoryModalVisibility(true);
								}}
							/>
							<div
								className={classes.box}
								onClick={() => {
									context.selectCategory(each);
									history.push("/question");
								}}
							>
								<div className={classes.box_text}>
									<h4 className={classes.box_title}>{each.name}</h4>
									<h6 className={classes.box_qty}>{each.count}</h6>
								</div>
							</div>
						</div>
					))}
			</div>
		)
	);
}
