import React from "react";

export default function Loader({ isLoading }) {
	return (
		isLoading && (
			<div class="spinner-border m-5" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		)
	);
}
