import { useContext, useState, useEffect } from "react";
import { Context } from "../../Context";
import classes from "./list.module.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import rubbish from "../../images/rubbish-inverted.png";
import change from "../../images/change-inverted.png";
import { axiosCall } from "../../Config";
import Modal from "react-bootstrap/Modal";

const List = () => {
	const context = useContext(Context);

	const InitialQuestionState = [
		{
			id: "",
			question: "",
			answers: [""],
		},
	];

	const [questionState, setQuestionState] = useState(InitialQuestionState);

	const removeWord = (wordId) =>
		axiosCall(
			"delete",
			"/question",
			{
				data: {
					id: wordId,
				},
			},
			context.state.token.value
		)
			.then((_) => {
				setQuestionState((prev) => prev.filter((x) => x.id != wordId));
			})
			.catch((err) => {});

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		if (context.state.selectedCategory != null) {
			axiosCall(
				"post",
				"/question/by-category",
				{ categoryId: context.state.selectedCategory.id },
				context.state.token.value
			)
				.then((res) => {
					const data = res.data.map((x) => ({
						id: x.id,
						question: x.value,
						answers: x.answers,
					}));

					setQuestionState(data);
				})
				.catch((err) => console.log(err));
		}
	}, []);

	return (
		<>
			<Modal show={show} onHide={handleClose} animation={false}>
				<Modal.Header>
					<Modal.Title>Modal heading</Modal.Title>
				</Modal.Header>
				<Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleClose}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
			<h1 className={classes.header}>
				The category is `{context.state.selectedCategory?.name}`
			</h1>
			<Table striped bordered hover variant="dark" responsive="xl">
				<thead>
					<tr>
						<th>#</th>
						<th>Questions</th>
						<th>Answers</th>
						<th>Options</th>
					</tr>
				</thead>
				<tbody>
					{questionState.map((word, index) => (
						<tr key={word.id}>
							<td>{index + 1}</td>
							<td>{word.question}</td>
							<td>{word.answers[0]}</td>
							<td style={{ display: "flex" }}>
								<img
									src={change}
									className={classes.change}
									onClick={handleShow}
								/>{" "}
								<img
									src={rubbish}
									className={classes.rubbish}
									onClick={() => removeWord(word.id)}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
};

export default List;
