import axios from "axios";

const instance = axios.create({
	baseURL:
		process.env.NODE_ENV === "production"
			? "https://api.words.localx.host"
			: `https://localhost:${process.env.REACT_APP_PORT}`,
	timeout: 20000,
	withCredentials: true,
});

export function axiosCall(METHOD, URL, DATA, TOKEN) {
	if (TOKEN != undefined && TOKEN != null) {
		instance.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;
	}

	return instance[METHOD](URL, DATA);
}
