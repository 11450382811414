import React, { useContext, useEffect } from "react";
import { axiosCall } from "../Config";
import { Context } from "../Context";
import { Redirect } from "react-router-dom";

export default function Logout() {
	const context = useContext(Context);
	useEffect(() => {
		localStorage.removeItem("token-value");
		localStorage.removeItem("token-expiration");
		context.loginHandler(false);
	}, []);
	if (context.state.isLogin) return <Redirect to="/login" />;
}
