import React, { useState, useContext, useEffect } from "react";
import AddCategoryModal from "../../common/components/AddCategoryModal";
import DeleteCategoryModal from "../../common/components/DeleteCategoryModal";
import CategoryBody from "./CategoryBody";
import Loader from "../../common/components/Loader";
import { Context } from "../../Context";
import classes from "./categories.module.css";
import { axiosCall } from "../../Config";

function Categories() {
	const context = useContext(Context);

	const [visible, setVisible] = useState(false);
	const [isDeleteCategoryModalVisible, setDeleteCategoryModalVisibility] =
		useState(false);
	const [deleteCategoryState, setDeleteCategoryState] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const handleErrorAlert = (err) => {
		setErrorMessage(err.response.data.message);
		setTimeout(() => setErrorMessage(""), 3000);
	};

	useEffect(() => {
		setIsLoading(true);
		if (!visible || !isDeleteCategoryModalVisible)
			axiosCall(
				"post",
				"/category/all",
				{ PageSize: 50, PageIndex: 0 },
				context.state.token?.value
			)
				.then((res) => {
					context.fillCategories(res.data);
				})
				.catch((err) => handleErrorAlert(err))
				.finally(() => setIsLoading(false));
	}, [visible, isDeleteCategoryModalVisible]);

	return (
		<div className={classes.container}>
			<div className={classes.section}>
				<div>
					<AddCategoryModal visible={visible} setVisible={setVisible} />
				</div>
				<div>
					<DeleteCategoryModal
						visible={isDeleteCategoryModalVisible}
						setVisible={setDeleteCategoryModalVisibility}
						categoryId={deleteCategoryState}
					/>
				</div>
				<div className={classes.title}>
					<h3>Choose A Category</h3>
				</div>
				<Loader isLoading={isLoading} />
				<CategoryBody
					isLoading={isLoading}
					setDeleteCategoryState={setDeleteCategoryState}
					setDeleteCategoryModalVisibility={setDeleteCategoryModalVisibility}
					setVisible={setVisible}
				/>
			</div>
		</div>
	);
}

export default Categories;
