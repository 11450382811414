import React from "react";
import { Link } from "react-router-dom";

export default function Header(props) {
	return (
		<div className="st">
			<Link to="/question">
				<button className="btn btn-outline-light">questions</button>
			</Link>
			<Link to="/category">
				<button className="btn btn-outline-light">category</button>
			</Link>
			<Link to="/words">
				<button className="btn btn-outline-light">words</button>
			</Link>
			<Link to="/logout">
				<button className="btn btn-outline-light">logout</button>
			</Link>
		</div>
	);
}
