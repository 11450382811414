import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { axiosCall } from "../../Config";
import classes from "./customModal.module.css";
import { Context } from "../../Context";

function DeleteCategoryModal({ visible, setVisible, categoryId }) {
	const context = useContext(Context);

	const submit = () =>
		axiosCall("delete", "/category", { data: { id: categoryId } });

	return (
		<Modal show={visible} onHide={() => setVisible(false)} centered>
			<Modal.Header className={classes.header}>
				<Modal.Title>Are you sure?</Modal.Title>
			</Modal.Header>
			<Modal.Body className={classes.body}>
				<h3>
					{`You are going to delete "${
						context.state.categories?.filter((x) => x.id == categoryId)[0]?.name
					}" category`}
					.{" "}
				</h3>
			</Modal.Body>
			<Modal.Footer className={classes.footer}>
				<Button variant="secondary" onClick={() => setVisible(false)}>
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						submit().finally(() => setVisible(false));
					}}
				>
					Delete
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default DeleteCategoryModal;
