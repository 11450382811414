import React, { useEffect, useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { axiosCall } from "../../Config";
import { Context } from "../../Context";

function Questions() {
	const context = useContext(Context);
	const [isLogin, setIsLogin] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const handleErrorAlert = (err) => {
		setErrorMessage(err.response.data.message);
		setTimeout(() => setErrorMessage(""), 3000);
	};

	const history = useHistory();
	const InitialQuestionState = [
		{
			id: "",
			question: "",
			answers: [""],
		},
	];

	const initialCurrentQuestionState = {
		id: "",
		question: "",
		answers: [""],
	};

	const [questionState, setQuestionState] = useState(InitialQuestionState);
	const [persistentQuestionState, setPersistentQuestionState] =
		useState(InitialQuestionState);

	const [currentQuestion, setCurrentQuestion] = useState(
		initialCurrentQuestionState
	);

	const [unknownQuestionState, setUnknownQuestionState] = useState([]);

	const [inputState, setInputState] = useState("");

	const correctAnswerCount = useRef(0);
	const inputBoxRef = useRef(null);

	// useEffect(() => {
	// 	console.log({ currentQuestion });
	// 	console.log({ questionState });
	// }, [questionState]);

	useEffect(() => {
		if (currentQuestion?.answers.some((x) => x == inputState)) {
			setQuestionState((prev) =>
				prev.filter((x) => x.id != currentQuestion.id)
			);

			setInputState("");
			correctAnswerCount.current += 1;
		}
	}, [inputState]);

	const onShowAnswerClick = () => {
		if (!unknownQuestionState.some((x) => x.id == currentQuestion.id)) {
			setUnknownQuestionState([...unknownQuestionState, currentQuestion]);
		}
	};

	useEffect(() => {
		if (context.state.selectedCategory != null) {
			setIsLogin(true);
			axiosCall(
				"post",
				"/question/by-category",
				{ categoryId: context.state.selectedCategory.id },
				context.state.token.value
			)
				.then((res) => {
					const data = res.data.map((x) => ({
						id: x.id,
						question: x.value,
						answers: x.answers,
					}));

					setQuestionState(data);
					setPersistentQuestionState(data);
				})
				.catch((err) => handleErrorAlert(err))
				.finally(() => setIsLogin(false));
		}
	}, []);

	useEffect(() => {
		if (questionState.length > 0) {
			const index = Math.floor(Math.random() * questionState.length);
			const element = questionState.find((_, i) => i == index);

			setCurrentQuestion(element);
			if (inputBoxRef.current != null) {
				inputBoxRef.current.placeholder = "";
			}
		} else {
			setCurrentQuestion(null);
		}
	}, [questionState]);

	if (context.state.selectedCategory == null) history.push("/category");

	return (
		<div>
			{isLogin ? (
				<>
					<h2>Questions</h2>
					<div class="spinner-border m-5" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
				</>
			) : (
				<>
					<h3 className="mt-3">{currentQuestion?.question}</h3>
					{currentQuestion != null ? (
						<div className="input-group my-5 maxW">
							<input
								type="text"
								className="form-control bg"
								aria-label="Username"
								aria-describedby="basic-addon1"
								value={inputState}
								onChange={(e) => setInputState(e.target.value)}
								ref={inputBoxRef}
							/>
							<button
								className="btn btn-outline-success"
								onClick={() => {
									onShowAnswerClick();
									inputBoxRef.current.focus();
									inputBoxRef.current.placeholder === ""
										? (inputBoxRef.current.placeholder =
												currentQuestion.answers[0])
										: (inputBoxRef.current.placeholder = "");
								}}
							>
								answer
							</button>
						</div>
					) : (
						<div>
							<h4>
								Result is{" "}
								{(
									((persistentQuestionState.length -
										unknownQuestionState.length) *
										100) /
									persistentQuestionState.length
								).toFixed(1)}
								% , unknown word count is {unknownQuestionState.length}
							</h4>
							<button
								className="btn btn-outline-light mt-4"
								onClick={() => {
									setQuestionState(persistentQuestionState);
									setUnknownQuestionState([]);
									setTimeout(() => inputBoxRef.current.focus(), 100);
								}}
							>
								Again
							</button>
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default Questions;
